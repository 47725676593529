<template>
  <dialogComp
    title="发货量排名"
    :isShow="isShow"
    @close="close"
    confirmText="选择"
    @sure="sure"
  >
  <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
    <el-form-item label="商家发货排行" prop="rank">
      <el-date-picker
        v-model="formData.month"
        type="month"
        size="small"
        placeholder="月份"
        @change="monthChange"
      >
      </el-date-picker>
    </el-form-item>
     <el-form-item label="" prop="storeId">
   <el-radio-group v-model="formData.storeId" >
    <el-radio class="item" v-for="item in storeRankData" :key="item.id" :label="item.storeId">{{item.storeName}}</el-radio>
  </el-radio-group>
     </el-form-item>
  </el-form>
  </dialogComp>
</template>
<script>
import { storeGoodRank } from '@/api'
import { formatDate } from '@/utils/common'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      storeRankData: [],
      formData: {
        month: null,
        storeId: '',
        storeName: ''
      },
      rules: {}
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        this.formData.storeId = ''
        // this.formData.month = ''
        this.getList()
      }
    }
  },
  methods: {
    getList () {
      const month = this.formData.month ? formatDate(this.formData.month).slice(0, 7) : null
      storeGoodRank({ month }).then(res => {
        this.storeRankData = Object.freeze(res.data)
      })
    },
    monthChange () {
      this.getList()
    },
    close () {
      this.formData.month = null
      this.formData.storeId = ''
      this.storeRankData = []
      this.$emit('close')
    },
    sure () {
      if (!this.formData.storeId) {
        this.$message.error('请选择商家')
        return
      }
      this.formData.storeName = this.storeRankData.find(item => item.storeId === this.formData.storeId).storeName
      this.$emit('selectStore', this.formData)
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
  .item {
    display:block;
    margin-bottom:10px
  }
</style>
