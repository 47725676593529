<template>
  <dialogComp width="640px" title="开箱不良明细" :isShow="isShow" @close="close" :isShowFooter="false">
    <tableComp
      :data="tableData"
      :col-configs="theadName"
    >
    </tableComp>
  </dialogComp>
</template>
<script>
import { packCountDetail } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    orderCode: String
  },
  data () {
    return {
      tableData: [],
      theadName: [
        { prop: 'repairNum', label: '报修次数', width: 80 },
        { prop: 'serialNum', label: '序列号' },
        { prop: 'num', label: '数量', width: 80 },
        { prop: 'code', label: '工单号' }
      ]
    }
  },
  watch: {
    isShow (val) {
      val && this.getList()
    }
  },
  methods: {
    getList () {
      packCountDetail({ orderCode: this.orderCode }).then(res => {
        this.tableData = res.data
      })
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
